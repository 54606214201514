import React from "react";
import Layout from "../../components/UI/layout";
import SEO from "../../components/UI/seo";
import PhpDevSection from "../../components/Search/Details/Phpdev/phpdev"
import { graphql } from "gatsby";

const PhpDevPage = () => (    
    <Layout>
        <SEO title="Codeicus | PhpDev" />
        <PhpDevSection/>
    </Layout>
)

export default PhpDevPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;