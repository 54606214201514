
import React from 'react';
import * as styles from '../../../Projects/projects.module.scss'

import Tabs from  '../Tabs/tabs';
import Process from '../../../Projects/Process/process';
import Square from '../../../Projects/Square/square';
import dev1 from '../../../../images/Search/dev1.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const PhpDevSection = () => {

    const {t} = useTranslation();

    return (
        <section className={styles.projectSection}>
            <div className={styles.containProject}>
                <div className={styles.containBody}>
                    <div className={styles.contentInfoLeft}>
                        <Tabs
                        active = 'phpdev'/>

                        <Square
                            title = {t("detailsPage.phpdev.square.title")}
                            subtitle= {t("detailsPage.phpdev.square.subtitle")}
                            description = {t("detailsPage.phpdev.square.description")}
                            list = {[t("detailsPage.phpdev.square.list.0")]}                            
                            color = '#f0801f'
                            image = {dev1}
                        />      
                    </div>
                    <div className={styles.contentInfoRight}>                            
                        <Process 
                            number = '1'
                            title = {t("detailsPage.phpdev.process.1.title")}
                            description = {t("detailsPage.phpdev.process.1.title")}
                            list= {[t("detailsPage.phpdev.process.1.list.0"),
                                    t("detailsPage.phpdev.process.1.list.1"),
                                    t("detailsPage.phpdev.process.1.list.2"),
                                    t("detailsPage.phpdev.process.1.list.3"),
                                    t("detailsPage.phpdev.process.1.list.4")
                                ]}
                        />
                        <Process 
                            number = '2'
                            title = {t("detailsPage.phpdev.process.2.title")}
                            description = {t("detailsPage.phpdev.process.2.title")}
                            list= {[t("detailsPage.phpdev.process.2.list.0"),
                                    t("detailsPage.phpdev.process.2.list.1"),
                                    t("detailsPage.phpdev.process.2.list.2"),
                                    t("detailsPage.phpdev.process.2.list.3"),
                                    t("detailsPage.phpdev.process.2.list.4")
                                ]}
                        />                   
                    </div>
                </div>
            </div>
		</section>
    );
};
export default PhpDevSection;